import React, {useEffect, useState, useCallback} from 'react';
import {useHistory, useLocation} from 'react-router-dom';

import fp from 'lodash/fp';

import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Paper,
    makeStyles,
} from '@material-ui/core';
import {ExpandMore} from '@material-ui/icons';
import {Alert} from '@material-ui/lab';
import {useSnackbar} from 'notistack';

import BasePage from 'ccm/components/BasePage';

import {useApi} from '@arborian/narrf';

import {url_for} from 'ccm/routes';

import PccLoginButton from 'ccm/components/PccLoginButton';
import BaseLoginButton from 'ccm/components/BaseLoginButton';
import PasswordRegistrationForm from 'ccm/components/forms/PasswordRegistrationForm';

const useStyles = makeStyles(theme => ({
    root: {},
    welcomeMsgContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        gap: theme.spacing(3),
        padding: theme.spacing(7),
        paddingTop: theme.spacing(2),
    },
    logo: {
        width: '70%',
        height: 'auto',
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
    },
    loginButton: {
        width: 244,
        height: 40,
        marginTop: 6,
        marginBottom: 6,
        marginRight: 4,
    },
    accessList: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(4),
        '& ul': {
            margin: 0,
        },
    },
}));

export default function WelcomePage() {
    const api = useApi();
    const history = useHistory();
    const classes = useStyles();
    const {enqueueSnackbar} = useSnackbar();
    const [providers, setProviders] = useState();
    const [invite, setInvite] = useState();
    const [fetching, setFetching] = useState(true);
    const params = new URLSearchParams(useLocation().search);
    const nonce = params.get('inviteId');

    const fetchAuthProviders = useCallback(async () => {
        const redirect_uri = new URL('/callback', window.location.href);
        const authorizeLink = api.authorizeLink({
            redirect_uri,
            intent: 'login',
        });
        try {
            let rv = await fetch(authorizeLink);
            if (rv.status === 300) {
                let data = await rv.json();
                console.log('Got data', data);
                const providers = fp.pipe([
                    fp.get('options'),
                    fp.toPairs,
                    fp.map(([id, attributes]) => ({id, attributes})),
                ])(data);
                console.log('got providers', providers);
                setProviders(providers);
            }
        } catch (e) {
            console.log('Got error, trying direct redirect');
            window.location = authorizeLink;
        }
    }, [api, setProviders]);

    const fetchInvitation = useCallback(
        async invitation_nonce => {
            const url = api.url_for('invitation.InvitationPublic', {
                invitation_nonce,
            });
            try {
                const result = await api.fetchJsonApi(url);
                setInvite(result);
            } catch (err) {
                console.error(err);
                setInvite(null);
            }
            setFetching(false);
        },
        [api],
    );

    useEffect(() => {
        if (!providers) {
            fetchAuthProviders();
        }
    }, [providers, fetchAuthProviders]);

    useEffect(() => {
        fetchInvitation(nonce);
    }, [nonce, fetchInvitation]);

    const reportError = useCallback(
        error => {
            console.log({error});
            let errorMsg = `ERROR - Unable to complete your registration: ${fp.getOr(
                'Unknown error',
                'message',
                error,
            )}`;
            if (fp.get('response.status', error) === 409) {
                errorMsg =
                    'ERROR - An account already exists for this user. Please sign in instead.';
            }

            enqueueSnackbar(errorMsg, {
                persist: false,
                variant: 'error',
            });
        },
        [enqueueSnackbar],
    );

    const handlePasswordRegistration = useCallback(
        async ({name, email, password}) => {
            console.log('Try to register', name, email, password);
            try {
                const resp = await api.fetch(api.url_for('auth.registration'), {
                    method: 'POST',
                    json: {
                        data: {
                            type: 'Registration',
                            attributes: {name, email, password},
                        },
                    },
                });
                console.log('Response was', resp);
            } catch (error) {
                reportError(error);
                throw error;
            }
            try {
                await api.loginSrp(email, password);
                history.push(url_for('profile'));
            } catch (error) {
                reportError(error);
                throw error;
            }
        },
        [api, history, reportError],
    );
    const pcc = fp.find(p => p.attributes.plugin_id === 'pcc', providers);
    const nonPcc = fp.filter(p => p.attributes.plugin_id !== 'pcc', providers);

    const RegistrationForm = selectRegistrationForm(invite);
    const passwordFormValues = {
        name: '',
        email: fp.get('data.attributes.to_email', invite),
        password: '',
        password2: '',
    };

    return (
        <BasePage title='Welcome'>
            <div className={classes.root}>
                <Paper className={classes.welcomeMsgContainer}>
                    <img
                        className={classes.logo}
                        src='/img/SamePageMDLogo-2.png'
                        alt='Logo'
                    />
                    <br />
                    {fetching ? (
                        <h6>Fetching invitation...</h6>
                    ) : !invite ? (
                        <Alert severity='error'>
                            <strong>Invitation expired / not found</strong>
                            <br />
                            Please request a new invitation from your
                            administrator.
                        </Alert>
                    ) : (
                        <>
                            <h4>You've been invited to join SamePageMD</h4>
                            <div className={classes.accessList}>
                                <h6>
                                    Create a SamePageMD account to access the
                                    following:
                                </h6>
                                <ul>
                                    {fp.map(
                                        accessText => (
                                            <li>
                                                <p>{accessText}</p>
                                            </li>
                                        ),
                                        fp.get(
                                            'data.attributes.metadata.access_details',
                                            invite,
                                        ),
                                    )}
                                </ul>
                            </div>
                            <RegistrationForm
                                classes={classes}
                                pcc={pcc}
                                nonPcc={nonPcc}
                                handlePasswordRegistration={
                                    handlePasswordRegistration
                                }
                                passwordFormValues={passwordFormValues}
                            />
                        </>
                    )}
                </Paper>
            </div>
        </BasePage>
    );
}

function selectRegistrationForm(invite) {
    if (fp.get('data.attributes.metadata.related_patient', invite))
        return FamilyInvitation;
    if (fp.get('data.attributes.metadata.related_practice', invite))
        return PracticeInvitation;
    if (fp.get('data.attributes.metadata.related_facility', invite))
        return FacilityInvitation;
}

const PracticeInvitation = ({
    classes,
    pcc,
    nonPcc,
    handlePasswordRegistration,
    passwordFormValues,
}) => (
    <>
        <Alert severity='info'>
            <strong>Important:</strong> You must register using the same email
            address that recieved the invitation.
        </Alert>
        <br />
        <div className={classes.buttonContainer}>
            {fp.get('attributes.allow_signup', pcc) && (
                <PccLoginButton provider={pcc} intent='register' />
            )}
            {fp.pipe([
                fp.filter('attributes.allow_signup'),
                fp.filter(p => p.attributes.plugin_id !== 'password'),
                fp.map(p => (
                    <BaseLoginButton provider={p} intent='register'>
                        Sign up with {p.attributes.label}
                    </BaseLoginButton>
                )),
            ])(nonPcc)}
        </div>
        <br />
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                If you don't use any of the above methods, you may also register
                with an email & password
            </AccordionSummary>
            <AccordionDetails>
                <PasswordRegistrationForm
                    submitLabel='Sign up with password'
                    onSubmit={handlePasswordRegistration}
                    initialValues={passwordFormValues}
                />
            </AccordionDetails>
        </Accordion>
    </>
);

const FacilityInvitation = ({
    classes,
    pcc,
    handlePasswordRegistration,
    passwordFormValues,
}) => (
    <>
        <div className={classes.buttonContainer}>
            {fp.get('attributes.allow_signup', pcc) && (
                <PccLoginButton provider={pcc} intent='register' />
            )}
        </div>
        <br />
        <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />}>
                If your facility does not use PointClickCare, please register
                with an email & password
            </AccordionSummary>
            <AccordionDetails>
                <PasswordRegistrationForm
                    submitLabel='Sign up with password'
                    onSubmit={handlePasswordRegistration}
                    initialValues={passwordFormValues}
                />
            </AccordionDetails>
        </Accordion>
    </>
);

const FamilyInvitation = ({handlePasswordRegistration, passwordFormValues}) => (
    <>
        <Accordion defaultExpanded>
            <AccordionDetails>
                <PasswordRegistrationForm
                    submitLabel='Sign up with password'
                    onSubmit={handlePasswordRegistration}
                    initialValues={passwordFormValues}
                />
            </AccordionDetails>
        </Accordion>
    </>
);
